import { CreateOpsNoticeBoardGQL } from '../graphql/opsNoticeBoard/CreateOpsNoticeBoard'
import { GetAllAvailableOpsNoticeBoardGQL } from '../graphql/opsNoticeBoard/GetAllAvailableOpsNoticeBoard'
import { GetAvailableOpsNoticeBoardForDateGQL } from '../graphql/opsNoticeBoard/GetAvailableOpsNoticeBoardForDate'
import { GetNoticeboardHistoryByNoticeboardObjectIdGQL } from '../graphql/opsNoticeBoard/GetNoticeboardHistoryByNoticeboardObjectId'
import { RemoveNoticeBoardByObjectIdGQL } from '../graphql/opsNoticeBoard/RemoveNoticeBoardByObjectId'
import { UpdateNoticeBoardByObjectIdGQL } from '../graphql/opsNoticeBoard/UpdateNoticeBoardByObjectId'
import { ApolloService } from './Apollo'
import {
  V1RidaApiGetAvailableOpsNoticeBoardForDateResponse,
  V1RidaApiGetAvailableOpsNoticeBoardResponse,
  V1RidaApiGetNoticeboardHistoryByNoticeboardObjectIdResponse,
} from './Type'

class OpsNoticeBoard {
  public static async getAllAvailableOpsNoticeBoard(filter: {
    itemsPerPage: number
    pageNumber: number
  }) {
    const response =
      await ApolloService.client.query<V1RidaApiGetAvailableOpsNoticeBoardResponse>(
        {
          query: GetAllAvailableOpsNoticeBoardGQL,
          variables: {
            filter,
          },
        },
      )
    return response.data.getAllAvailableOpsNoticeBoard
  }

  public static async getAllAvailableOpsNoticeBoardForDate(filter: {
    itemsPerPage?: number
    pageNumber?: number
    date: string
  }) {
    const response =
      await ApolloService.client.query<V1RidaApiGetAvailableOpsNoticeBoardForDateResponse>(
        {
          query: GetAvailableOpsNoticeBoardForDateGQL,
          variables: {
            filter,
          },
        },
      )
    return response.data.getAvailableOpsNoticeBoardForDate
  }

  public static async getNoticeBoardHistory(
    noticeBoardObjectId: string,
    filter: {
      itemsPerPage: number
      pageNumber: number
    },
  ) {
    const response =
      await ApolloService.client.query<V1RidaApiGetNoticeboardHistoryByNoticeboardObjectIdResponse>(
        {
          query: GetNoticeboardHistoryByNoticeboardObjectIdGQL,
          variables: {
            noticeBoardObjectId,
            filter,
          },
        },
      )
    return response.data.getNoticeboardHistoryByNoticeboardObjectId
  }

  public static async createNoticeBoard(createdData: {
    title: string
    message: string
    priority: number
    valid: {
      from: string | null
      to: string | null
      daysOfWeek: string[] | null
    }
  }) {
    await ApolloService.client.mutate({
      mutation: CreateOpsNoticeBoardGQL,
      variables: {
        ...createdData,
      },
    })
  }

  public static async updateNoticeBoard(
    objectId: string,
    updatedData: {
      title: string
      message: string
      priority: number
      valid: {
        from: string | null
        to: string | null
        daysOfWeek: string[] | null
      }
    },
  ) {
    await ApolloService.client.mutate({
      mutation: UpdateNoticeBoardByObjectIdGQL,
      variables: {
        ...updatedData,
        objectId,
      },
    })
  }

  public static async removeNoticeBoard(objectId: string) {
    await ApolloService.client.mutate({
      mutation: RemoveNoticeBoardByObjectIdGQL,
      variables: {
        objectId,
      },
    })
  }
}

export { OpsNoticeBoard as OpsNoticeBoardService }
