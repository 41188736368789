import gql from 'graphql-tag'

export const GetUserInfoByUidGQL = gql`
  query ($uid: String!) {
    getUserInfoByUid(uid: $uid) {
      id
      uid
      email
      company
      phone
      billing
      createdTime
      firstName
      lastName
      role
      serviceLevel
      webhookUrl
      userBalance {
        id
        balance
      }
      config {
        marketplace {
          enabled
          fee
          requiredAdditionalCost
        }
        pinCode {
          enabled
        }
        podSignature {
          enabled
        }
        bulk4PL {
          enabled
          fee
          extraCostOnBasePrice {
            providers {
              provider
              extraCost
            }
          }
          useVariablePriceToOfferDriver {
            enabled
          }
        }
        selfManaged {
          enabled
          fee
          groupCode
        }
        fixedPrice {
          bulkOrder {
            enabled
            fee
            pricePerStopSamePostalCode
          }
          standardOrder {
            enabled
            fee
            pricePerStopSamePostalCode
          }
        }
        priceMatrix {
          enabled
          default
          minPrice
          matrix {
            vehicleType
            deliveryType
            distanceFromPickup
            size
            pickupTime
            windowDuration
            totalDropoffs
            price
          }
        }
        qrCode {
          enabled
          isRequiredToScanWhenPickup
        }
        batchingTiming {
          enabled
          nextdayCutoffTime
          samedayCutoffTime
          sameDayPickupTimeWithinXHours
          sameDayPickupTimeWithinXHoursIfFoundAnyDeliveries
        }
        providerMatching {
          enabled
          defaultExcludeProviders
          defaultIncludeProviders
          deliveryTypes {
            type
            excludeProviders
          }
        }
        placeOrderConfig {
          minimumTimeBetweenCurrentTimeAndPickupTimeInMin {
            enabled
            default {
              value
            }
            deliveryTypes {
              value
              type
            }
          }
          deliveryWindows {
            enabled
            allowedFixedWindows {
              start
              end
            }
          }
        }
        batchingOptions {
          batchingSplitWhenLate {
            enabled
            currentTimePassStartWindowThresholdInMin
            minimumLengthFromCurrentTimeToEndWindowToSplitInMin
          }
          batchingNextDay {
            enabled
          }
          batchingWithOtherMerchants {
            enabled
            merchants {
              id
              name
            }
          }
        }
        rerace {
          reraceThreshold {
            enabled
            totalDeliveriesInRoute {
              from
              to
              valueInMinute
            }
          }
          reraceProviderNotMoreThanXValueCompareToLalamovePrice {
            enabled
            value
          }
        }
        sms {
          pricePerStop
          alwaysEnabledSms
          notifyOrderToRecipient {
            smsContent
          }
        }
        autoTip {
          enabled
          intervalXMinsAfterPickupTime
          tipAmountPerInterval
          maxTotalTipAmount
          chargeTipToDeliveryUnderFixedPrice
          autoTipStartFromPickupTimeMin
          perProviders {
            provider
            intervalMin
            tipAmountPerInterval
            maxTotalTipAmount
            autoTipStartFromPickupTimeMin
          }
        }
        antiStacking {
          enabled
          excludeProviders
        }
        providerApiKey {
          providers {
            name
            key
            secret
            enabled
          }
        }
        autoAssign {
          hyperLocal {
            enabled
          }
        }
        batchingConfig {
          maxStopPerRoute
          dropOffServiceTime
        }
      }
      apiKey {
        appId
        clientSecret
      }
    }
  }
`
