export const DEFAULT_FORMAT = {
  DATE: 'YYYY-MM-DD',
  DATE_2: "DD MMM 'YY",
  DATE_TIME: 'YYYY/MM/DD hh:mmA',
  DATE_TIME_2: "DD MMM 'YY h:mm A",
  DATE_TIME_3: 'llll',
  DATE_TIME_5: 'DD/MM HH:mm',
  DATE_TIME_6: 'DD/MM HH:mm:ss',
  TIME_ONLY: 'h:mm A',
  HOUR_ONLY: 'h A',
  DATE_TIME_4: 'YYYY-MM-DD HH:mm',
  TIME_ONLY_2: 'HH:mm',
}
export const TZ = {
  SG: 'Asia/Singapore',
}
export const SMS_STATUS = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
}
export const DEFAULT_TZ = TZ.SG
export const NUXT_EVENT = {
  DRAWER_OPEN: 'event:nuxt:drawer-open',
}
export const ORDER_STATUS = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  RACING: 'RACING',
  BATCHING: 'BATCHING',
  ASSIGNED: 'ASSIGNED',
  ONGOING: 'ONGOING',
  PICKED_UP: 'PICKEDUP',
  FULFILLED: 'FULFILLED',
  CUSTOMER_CANCELLED: 'CUSTOMER_CANCELLED',
  DRIVER_CANCELLED: 'DRIVER_CANCELLED',
  ADMIN_CANCELLED: 'ADMIN_CANCELLED',
  INSUFFICIENT_CREDIT: 'INSUFFICIENT_CREDIT',
  UNDELIVERED: 'UNDELIVERED',
  UNFULFILLED: 'UNFULFILLED',
  CANCELLED: 'CANCELLED',
  FAILED_TO_DELIVER: 'FAILED_TO_DELIVER',
}

export const DELIVERY_TYPE = {
  POOL: 'POOL',
  POOL_MPLACE: 'POOL_MPLACE',
  RACE: 'RACE',
  RACE_MPLACE: 'RACE_MPLACE',
  DELIVERY: 'DELIVERY',
  BULK_4PL: 'SD_BULK_4PL',
  SELF_MANAGED: 'SELF_MANAGED',
}

export const WAYPOINT_TYPE = {
  PICK_UP: 'PICKUP',
  DROP_OFF: 'DROPOFF',
}

export const USER_ROLE = {
  ADMIN: 'ADMIN',
  SUPPORT: 'SUPPORT',
  OPS: 'OPS',
}

export const PROVIDER = {
  RIDAGO: 'ridago',
  PANDAGO: 'pandago',
  LALAMOVE: 'lalamove',
  MILKRUN: 'milkrun',
  GOGOX: 'gogox',
  TELEPORT: 'teleport',
  DLVRD: 'DLVRD',
  DLVRD_PRIORITY: 'DLVRDPriority',
  GRAB: 'grabExpress',
  TADA: 'tada',
}

export const SERVICE_LEVEL = {
  PLUS: 'PLUS',
  BASIC: 'BASIC',
}

export const CLONE_TYPE = {
  SINGLE_DELIVERY: 'SINGLE_DELIVERY',
  WHOLE_ROUTE: 'WHOLE_ROUTE',
}

export const VEHICLE_TYPE = {
  CAR: 'CAR',
  BIKE: 'BIKE',
  MINIVAN: 'MINIVAN',
  VAN: 'VAN',
  WALKER: 'WALKER',
}

export const ROUTE_FILTER_TYPE = {
  RACING_NOT_MATCH: 'RACING_NOT_MATCH',
  ASSIGNED_NO_PICKUP: 'ASSIGNED_NO_PICKUP',
  LATE: 'LATE',
  RACING_NOT_MATCH_AFTER_PICKUP: 'RACING_NOT_MATCH_AFTER_PICKUP',
  DRIVER_CANCELLED: 'DRIVER_CANCELLED',
}
