import axios from 'axios'
import { Dayjs } from 'dayjs'

import { AddTipForDeliveryByObjectIdGQL } from '../graphql/delivery/AddTipForDeliveryByObjectId'
import { CancelDeliveryByObjectIdGQL } from '../graphql/delivery/CancelDeliveryByObjectId'
import { CancelRouteByDeliveryObjectIdGQL } from '../graphql/delivery/CancelRouteByDeliveryObjectId'
import { ChooseRacingProvidersForDeliveryByObjectIdGQL } from '../graphql/delivery/ChooseRacingProvidersForDeliveryByObjectId'
import { CloneDeliveryByObjectIdGQL } from '../graphql/delivery/CloneDeliveryByObjectId'
import { CompleteRouteByObjectIdGQL } from '../graphql/delivery/CompleteRouteByObjectId'
import { FailDeliveryByObjectIdGQL } from '../graphql/delivery/FailDeliveryByObjectId'
import { ConfirmPodUploadGQL } from '../graphql/delivery/ConfirmPodUpload'
import { CreateCommentForDeliveryGQL } from '../graphql/delivery/CreateCommentForDelivery'
import { GetDeliveriesByOrderObjectIdGQL } from '../graphql/delivery/GetDeliveriesByOrderObjectId'
import { GetDeliveryByObjectIdGQL } from '../graphql/delivery/GetDeliveryByObjectId'
import { GetDeliveryCommentsByObjectIdGQL } from '../graphql/delivery/GetDeliveryCommentsByObjectId'
import { GetDeliveryHistoryByObjectIdGQL } from '../graphql/delivery/GetDeliveryHistoryByObjectId'
import { GetDeliveryQuotationGQL } from '../graphql/delivery/GetDeliveryQuotation'
import { GetDeliverySmsByObjectIdGQL } from '../graphql/delivery/GetDeliverySmsByObjectId'
import { GetIncompleteDeliveriesGQL } from '../graphql/delivery/GetIncompleteDeliveries'
import { GetPodPresignedUrlGQL } from '../graphql/delivery/GetPodPresignedUrl'
import { GetSummaryUnfulfilledDeliveriesForDateGQL } from '../graphql/delivery/GetSummaryUnfulfilledDeliveriesForDate'
import { OptimizeClonedDeliveryGQL } from '../graphql/delivery/OptimizeClonedDelivery'
import { PlaceDeliveryOrderGQL } from '../graphql/delivery/PlaceDeliveryOrder'
import { RaceDeliveryGQL } from '../graphql/delivery/RaceDelivery'
import { ReraceDeliveryByObjectIdGQL } from '../graphql/delivery/ReraceDeliveryByObjectId'
import { UpdateProviderDetailsForDeliveryGQL } from '../graphql/delivery/UpdateProviderDetailsForDelivery'
import { UpdateDeliveryDetailByObjectIdGQL } from '../graphql/delivery/UpdateDeliveryDetailByObjectId'
import { ApolloService } from './Apollo'
import { DEFAULT_FORMAT } from './Constant'
import {
  V1RidaApiCancelDeliveryByObjectIdResponse,
  V1RidaApiCancelRouteByDeliveryObjectIdResponse,
  V1RidaApiCloneDeliveryByObjectIdResponse,
  V1RidaApiGetDeliveriesByOrderObjectIdResponse,
  V1RidaApiGetDeliveryByObjectIdResponse,
  V1RidaApiGetDeliveryCommentsByObjectIdResponse,
  V1RidaApiGetDeliveryHistoryByObjectIdResponse,
  V1RidaApiGetDeliveryQuotationResponse,
  V1RidaApiGetDeliverySmsByObjectIdResponse,
  V1RidaApiGetIncompleteDeliveriesResponse,
  V1RidaApiGetPodPresignedUrlResponse,
  V1RidaApiGetSummaryUnfulfilledDeliveriesForDateResponse,
  V1RidaApiOptimizeClonedDeliveryResponse,
  V1RidaApiPlaceDeliverOrderResponse,
  V1RidaApiReraceDeliveryByObjectIdResponse,
  V1RidaApiUpdateProviderDetailsForDeliveryResponse,
} from './Type'

class Delivery {
  public static async updateProviderDetails(
    objectId: string,
    provider: string,
    deliveryId: string,
    cost: string,
    confirmation: boolean,
    finalCost: string,
    uncancelDeliveryObjectId: string[],
  ) {
    const response =
      await ApolloService.client.mutate<V1RidaApiUpdateProviderDetailsForDeliveryResponse>(
        {
          mutation: UpdateProviderDetailsForDeliveryGQL,
          variables: {
            objectId,
            provider,
            deliveryId,
            cost,
            confirmation,
            finalCost,
            uncancelDeliveryObjectId,
          },
        },
      )

    return response.data?.updateProviderDetailsForDelivery
  }

  public static async raceDelivery(objectId: string) {
    await ApolloService.client.mutate({
      mutation: RaceDeliveryGQL,
      variables: {
        objectId,
      },
    })
  }

  public static async getDeliveries(orderObjectId: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetDeliveriesByOrderObjectIdResponse>(
        {
          query: GetDeliveriesByOrderObjectIdGQL,
          variables: { orderObjectId },
        },
      )

    return response.data
  }

  public static async getDeliveryByObjectId(objectId: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetDeliveryByObjectIdResponse>({
        query: GetDeliveryByObjectIdGQL,
        variables: { objectId },
      })
    return response.data
  }

  public static async getDeliveryCommentsByObjectId(
    objectId: string,
    filterOptions?: {
      itemsPerPage?: number
      pageNumber?: number
    },
  ) {
    const response =
      await ApolloService.client.query<V1RidaApiGetDeliveryCommentsByObjectIdResponse>(
        {
          query: GetDeliveryCommentsByObjectIdGQL,
          variables: {
            objectId,
            filter: {
              itemsPerPage: filterOptions?.itemsPerPage,
              pageNumber: filterOptions?.pageNumber,
            },
          },
        },
      )
    return response.data
  }

  public static async createCommentForDelivery(
    objectId: string,
    comment: string,
  ) {
    return await ApolloService.client.mutate({
      mutation: CreateCommentForDeliveryGQL,
      variables: {
        objectId,
        comment,
      },
    })
  }

  public static async getIncompleteDeliveries(filterOptions?: {
    itemsPerPage?: number
    pageNumber?: number
  }) {
    const response =
      await ApolloService.client.query<V1RidaApiGetIncompleteDeliveriesResponse>(
        {
          query: GetIncompleteDeliveriesGQL,
          variables: {
            filter: {
              itemsPerPage: filterOptions?.itemsPerPage,
              pageNumber: filterOptions?.pageNumber,
            },
          },
        },
      )
    return response.data
  }

  public static async cancelDeliveryByObjectId(deliveryObjectId: string) {
    const response =
      await ApolloService.client.mutate<V1RidaApiCancelDeliveryByObjectIdResponse>(
        {
          mutation: CancelDeliveryByObjectIdGQL,
          variables: {
            objectId: deliveryObjectId,
          },
        },
      )

    return response.data?.cancelDeliveryByObjectId
  }

  public static async cancelRouteByDeliveryObjectId(deliveryObjectId: string) {
    const response =
      await ApolloService.client.mutate<V1RidaApiCancelRouteByDeliveryObjectIdResponse>(
        {
          mutation: CancelRouteByDeliveryObjectIdGQL,
          variables: {
            objectId: deliveryObjectId,
          },
        },
      )

    return response.data?.cancelRouteByDeliveryObjectId
  }

  public static async cloneDeliveryByObjectId(
    deliveryObjectId: string,
    cloneType: string,
  ) {
    const r =
      await ApolloService.client.mutate<V1RidaApiCloneDeliveryByObjectIdResponse>(
        {
          mutation: CloneDeliveryByObjectIdGQL,
          variables: {
            objectId: deliveryObjectId,
            cloneType,
          },
        },
      )

    return r.data?.cloneDeliveryByObjectId
  }

  public static async getDeliveryQuotation(
    userObjectId: string,
    delivery: {
      pickupTime: string
      vehicleType: string
      deliveryType: string
      window: string | null
      stops: {
        type: string
        address1: string
        address2: string
        postcode: string
        location: {
          lat: string
          lng: string
        }
        name: string
        phone: string
        items?:
          | {
              notes: string
              size: number
              category: string
              customId: string
            }[]
          | null
      }[]
    },
  ) {
    const r =
      await ApolloService.client.mutate<V1RidaApiGetDeliveryQuotationResponse>({
        mutation: GetDeliveryQuotationGQL,
        variables: {
          userObjectId,
          delivery,
        },
      })

    return r.data?.getDeliveryQuotation
  }

  public static async placeDeliveryOrder(
    userObjectId: string,
    delivery: {
      pickupTime: string
      vehicleType: string
      deliveryType: string
      window: string | null
      totalPrice: string
      selectedProviders: string[] | null
      stops: {
        // applicable to POOL related orders, as there will be price per stop
        // for that, for RACE, use totalPrice is enough
        pricePerStop?: string
        type: string
        address1: string
        address2: string
        postcode: string
        location: {
          lat: string
          lng: string
        }
        name: string
        phone: string
        pinCode: string | null
        qrCode: string | null
        additionalDriverEquipment: string | null
        items?:
          | {
              notes: string
              size: number
              category: string
              customId: string
            }[]
          | null
      }[]
    },
  ) {
    const r =
      await ApolloService.client.mutate<V1RidaApiPlaceDeliverOrderResponse>({
        mutation: PlaceDeliveryOrderGQL,
        variables: {
          userObjectId,
          delivery,
        },
      })

    return r.data?.placeDeliveryOrder
  }

  public static async addTipForDelivery(
    objectId: string,
    tipAmount: string,
    providers: string[],
  ) {
    await ApolloService.client.mutate({
      mutation: AddTipForDeliveryByObjectIdGQL,
      variables: {
        objectId,
        tipAmount,
        providers,
      },
    })
  }

  public static async getHistory(objectId: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetDeliveryHistoryByObjectIdResponse>(
        {
          query: GetDeliveryHistoryByObjectIdGQL,
          variables: { objectId },
        },
      )

    return response.data.getDeliveryHistoryByObjectId
  }

  public static async getPodPresignedUrl(
    waypointId: number,
    fileExtension: string,
  ) {
    const response =
      await ApolloService.client.mutate<V1RidaApiGetPodPresignedUrlResponse>({
        mutation: GetPodPresignedUrlGQL,
        variables: { waypointId, fileExtension },
      })

    return response.data?.getPodPresignedUrl
  }

  public static async confirmPodUpload(
    waypointId: number,
    fileExtension: string,
    dlvrdDocumentId?: string,
  ) {
    await ApolloService.client.mutate({
      mutation: ConfirmPodUploadGQL,
      variables: { waypointId, fileExtension, dlvrdDocumentId },
    })
  }

  public static async uploadPresignedUrl(
    url: string,
    data: Blob,
    contentType: string,
  ) {
    await axios.put(url, data, {
      headers: {
        'Content-Type': contentType,
      },
    })
  }

  public static async completeRoute(objectId: string) {
    await ApolloService.client.mutate({
      mutation: CompleteRouteByObjectIdGQL,
      variables: {
        objectId,
      },
    })
  }

  public static async failDelivery(objectId: string, failedReason: string) {
    await ApolloService.client.mutate({
      mutation: FailDeliveryByObjectIdGQL,
      variables: {
        objectId,
        failedReason,
      },
    })
  }

  public static async reraceDelivery(
    objectId: string,
    uncancelDeliveryObjectId: string[],
    racingProviders: string[],
  ) {
    const response =
      await ApolloService.client.mutate<V1RidaApiReraceDeliveryByObjectIdResponse>(
        {
          mutation: ReraceDeliveryByObjectIdGQL,
          variables: {
            objectId,
            uncancelDeliveryObjectId,
            racingProviders,
          },
        },
      )

    return response.data?.reraceDeliveryByObjectId
  }

  public static async optimizeClonedDeliveries(deliveryData: {
    stops: {
      type: string
      location: {
        lat: string
        lng: string
      }
    }[]
  }) {
    const response =
      await ApolloService.client.mutate<V1RidaApiOptimizeClonedDeliveryResponse>(
        {
          mutation: OptimizeClonedDeliveryGQL,
          variables: {
            delivery: deliveryData,
          },
        },
      )

    return response.data?.optimizeClonedDelivery
  }

  public static async chooseRacingProvidersForDelivery(
    deliveryObjectId: string,
    selectedProviders: string[],
  ) {
    await ApolloService.client.mutate({
      mutation: ChooseRacingProvidersForDeliveryByObjectIdGQL,
      variables: {
        selectedProviders,
        objectId: deliveryObjectId,
      },
    })
  }

  public static async updateDeliveryDetailByObjectId(
    deliveryObjectId: string,
    stops: {
      index: number
      phone: string | null
      name: string | null
      customId: string | null
      notes: string | null
    }[],
  ) {
    await ApolloService.client.mutate({
      mutation: UpdateDeliveryDetailByObjectIdGQL,
      variables: {
        objectId: deliveryObjectId,
        stops,
      },
    })
  }

  public static async getDeliverySmsByObjectId(objectId: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetDeliverySmsByObjectIdResponse>(
        {
          query: GetDeliverySmsByObjectIdGQL,
          variables: { objectId },
        },
      )

    return response.data.getDeliverySmsByObjectId
  }

  public static async getSummaryUnfulfilledDeliveriesForDate(date: Dayjs) {
    const response =
      await ApolloService.client.query<V1RidaApiGetSummaryUnfulfilledDeliveriesForDateResponse>(
        {
          query: GetSummaryUnfulfilledDeliveriesForDateGQL,
          variables: { date: date.format(DEFAULT_FORMAT.DATE) },
        },
      )

    return response.data.getSummaryUnfulfilledDeliveriesForDate
  }
}

export { Delivery as DeliveryService }
