import gql from 'graphql-tag'

export const GetDeliverySmsByObjectIdGQL = gql`
  query ($objectId: String!) {
    getDeliverySmsByObjectId(objectId: $objectId) {
      smses {
        message
        status
        createdTime
        type
        phone
        smsProvider
        smsMethod
        retryAt
        retrySmsProvider
        retrySmsMethod
        waypoint {
          address1
          address2
        }
      }
    }
  }
`
