import gql from 'graphql-tag'

export const UpdateNoticeBoardByObjectIdGQL = gql`
  mutation (
    $objectId: String
    $title: String
    $message: String
    $valid: OpsNoticeBoardValidInput
    $priority: Int
  ) {
    updateNoticeBoardByObjectId(
      title: $title
      message: $message
      valid: $valid
      priority: $priority
      objectId: $objectId
    )
  }
`
