import { Dayjs } from 'dayjs'

import { GenerateApiKeyGQL } from '../graphql/user/GenerateApiKey'
import { GetMerchantsGQL } from '../graphql/user/GetMerchants'
import { GetUserBillingTypesGQL } from '../graphql/user/GetUserBillingTypes'
import { GetUserConfigHistoryGQL } from '../graphql/user/GetUserConfigHistory'
import { GetUserInfoGQL } from '../graphql/user/GetUserInfo'
import { GetUserInfoByUidGQL } from '../graphql/user/GetUserInfoByUid'
import { GetUserRolesGQL } from '../graphql/user/GetUserRoles'
import { GetUsersGQL } from '../graphql/user/GetUsers'
import { GetUserServiceLevelsGQL } from '../graphql/user/GetUserServiceLevels'
import { RegenerateApiKeyGQL } from '../graphql/user/RegenerateApiKey'
import { UpdateUserConfigGQL } from '../graphql/user/UpdateUserConfig'
import { UpdateUserDetailsGQL } from '../graphql/user/UpdateUserDetails'
import { ApolloService } from './Apollo'
import { DEFAULT_FORMAT } from './Constant'
import {
  V1RidaApiGetAllUsersResponse,
  V1RidaApiGetMerchantsResponse,
  V1RidaApiGetUserBillingTypesResponse,
  V1RidaApiGetUserConfigHistoryResponse,
  V1RidaApiGetUserInfoResponse,
  V1RidaApiGetUserRolesResponse,
  V1RidaApiGetUserServiceLevelsResponse,
  V1RidaGetUserInfoByUidResponse,
} from './Type'
import { AddWebhookForUserGQL } from '../graphql/user/AddWebhookForUser'

class User {
  public static async getInfo() {
    const response =
      await ApolloService.client.query<V1RidaApiGetUserInfoResponse>({
        query: GetUserInfoGQL,
      })

    return response.data
  }

  public static async getUsers(filterOptions?: {
    itemsPerPage?: number
    pageNumber?: number
    searchTerm?: string
  }) {
    const response =
      await ApolloService.client.query<V1RidaApiGetAllUsersResponse>({
        query: GetUsersGQL,
        variables: {
          filter: {
            itemsPerPage: filterOptions?.itemsPerPage,
            pageNumber: filterOptions?.pageNumber,
            searchTerm: filterOptions?.searchTerm,
          },
        },
      })
    return response.data
  }

  public static async getUserInfoByUid(uid: string) {
    const response =
      await ApolloService.client.query<V1RidaGetUserInfoByUidResponse>({
        query: GetUserInfoByUidGQL,
        variables: {
          uid,
        },
      })
    return response.data
  }

  public static async getMerchants(filterOptions?: {
    startDate?: Dayjs
    endDate?: Dayjs
  }) {
    const response =
      await ApolloService.client.query<V1RidaApiGetMerchantsResponse>({
        query: GetMerchantsGQL,
        variables: {
          filter: {
            startDate: filterOptions?.startDate?.format(DEFAULT_FORMAT.DATE),
            endDate: filterOptions?.endDate?.format(DEFAULT_FORMAT.DATE),
          },
        },
      })
    return response.data
  }

  public static async getUserServiceLevels() {
    const response =
      await ApolloService.client.query<V1RidaApiGetUserServiceLevelsResponse>({
        query: GetUserServiceLevelsGQL,
      })

    return response.data
  }

  public static async getUserBillingTypes() {
    const response =
      await ApolloService.client.query<V1RidaApiGetUserBillingTypesResponse>({
        query: GetUserBillingTypesGQL,
      })

    return response.data
  }

  public static async getUserRoles() {
    const response =
      await ApolloService.client.query<V1RidaApiGetUserRolesResponse>({
        query: GetUserRolesGQL,
      })
    return response.data
  }

  public static async updateUserDetails(
    objectId: string,
    company: string | null,
    serviceLevel: string,
    userRole: string | null,
    billingType: string,
  ) {
    await ApolloService.client.mutate({
      mutation: UpdateUserDetailsGQL,
      variables: {
        objectId,
        company,
        serviceLevel,
        userRole,
        billingType,
      },
    })
  }

  public static async updateUserConfig(
    userObjectId: string,
    config: {
      marketplace: {
        fee: string
        enabled: boolean
        requiredAdditionalCost: boolean
      }
      bulk4PL: {
        fee: string
        enabled: boolean
        extraCostOnBasePrice: {
          providers: {
            provider: string
            extraCost: string
          }[]
        }
        useVariablePriceToOfferDriver: {
          enabled: boolean
        }
      }
      fixedPrice: {
        bulkOrder: {
          enabled: boolean
          fee: string
          pricePerStopSamePostalCode: string | null
        }
        standardOrder: {
          enabled: boolean
          fee: string
          pricePerStopSamePostalCode: string | null
        }
      }
      priceMatrix: {
        enabled: boolean
        default: string | null
        minPrice: string | null
        matrix: {
          vehicleType?: string[]
          deliveryType?: string[]
          distanceFromPickup?: number[][]
          size?: number[][]
          pickupTime?: string[][]
          windowDuration?: number[][]
          totalDropoffs?: number[][]
          price: string
        }[]
      }
      selfManaged: {
        fee: string
        enabled: boolean
        groupName?: string | null
      }
      pinCode: {
        enabled: boolean
      }
      podSignature: {
        enabled: boolean
      }
      qrCode: {
        enabled: boolean
        isRequiredToScanWhenPickup: boolean
      }
      batchingTiming: {
        enabled: boolean
        nextdayCutoffTime: string | null
        samedayCutoffTime: string | null
        sameDayPickupTimeWithinXHours: number | null
        sameDayPickupTimeWithinXHoursIfFoundAnyDeliveries: number | null
      }
      providerMatching: {
        enabled: boolean
        defaultExcludeProviders: string[]
        defaultIncludeProviders: string[]
        deliveryTypes: {
          type: string
          excludeProviders: string[]
        }[]
      }
      placeOrderConfig: {
        minimumTimeBetweenCurrentTimeAndPickupTimeInMin: {
          enabled: boolean
          default: {
            value: number
          }
          deliveryTypes: {
            type: string
            value: number
          }[]
        }
        deliveryWindows: {
          enabled: boolean
          allowedFixedWindows: {
            start: string
            end: string
          }[]
        }
      }
      batchingOptions: {
        batchingSplitWhenLate: {
          enabled: boolean
          currentTimePassStartWindowThresholdInMin: number
          minimumLengthFromCurrentTimeToEndWindowToSplitInMin: number
        }
        batchingNextDay: {
          enabled: boolean
        }
        batchingWithOtherMerchants: {
          enabled: boolean
          merchants: {
            id: number
            name: string
          }[]
        }
      }
      rerace: {
        reraceThreshold: {
          enabled: boolean
          totalDeliveriesInRoute: {
            from: number | null
            to: number | null
            valueInMinute: number
          }[]
        }
        reraceProviderNotMoreThanXValueCompareToLalamovePrice: {
          enabled: boolean
          value: string
        }
      }
      sms: {
        pricePerStop: string
        alwaysEnabledSms: boolean
        notifyOrderToRecipient: {
          smsContent: string
        }
      }
      autoTip: {
        enabled: boolean
        intervalXMinsAfterPickupTime: number
        tipAmountPerInterval: string
        maxTotalTipAmount: string
        chargeTipToDeliveryUnderFixedPrice: boolean
        autoTipStartFromPickupTimeMin: number
        perProviders: {
          provider: string
          intervalMin: number
          tipAmountPerInterval: string
          maxTotalTipAmount: string
          autoTipStartFromPickupTimeMin: number
        }[]
      }
      antiStacking: {
        enabled: boolean
        excludeProviders: string[]
      }
      providerApiKey: {
        providers: {
          name: string
          key: string
          secret: string
          enabled: boolean
        }[]
      }
      autoAssign: {
        hyperLocal: {
          enabled: boolean
        }
      }
      batchingConfig: {
        maxStopPerRoute: null | number
        dropOffServiceTime: number
      }
    },
  ) {
    await ApolloService.client.mutate({
      mutation: UpdateUserConfigGQL,
      variables: {
        userObjectId,
        marketplace: config.marketplace,
        bulk4PL: config.bulk4PL,
        fixedPrice: config.fixedPrice,
        priceMatrix: config.priceMatrix,
        selfManaged: config.selfManaged,
        pinCode: config.pinCode,
        podSignature: config.podSignature,
        qrCode: config.qrCode,
        batchingTiming: config.batchingTiming,
        providerMatching: config.providerMatching,
        placeOrderConfig: config.placeOrderConfig,
        batchingOptions: config.batchingOptions,
        rerace: config.rerace,
        sms: config.sms,
        autoTip: config.autoTip,
        antiStacking: config.antiStacking,
        providerApiKey: config.providerApiKey,
        autoAssign: config.autoAssign,
        batchingConfig: config.batchingConfig,
      },
    })
  }

  public static generateApiKey(userObjectId: string) {
    return ApolloService.client.mutate({
      mutation: GenerateApiKeyGQL,
      variables: {
        userObjectId,
      },
    })
  }

  public static regenerateApiKey(userObjectId: string) {
    return ApolloService.client.mutate({
      mutation: RegenerateApiKeyGQL,
      variables: {
        userObjectId,
      },
    })
  }

  public static addWebhookForUser(userObjectId: string, webhookUrl: string) {
    return ApolloService.client.mutate({
      mutation: AddWebhookForUserGQL,
      variables: {
        userObjectId,
        webhookUrl,
      },
    })
  }

  public static async getUserConfigHistory(
    userObjectId: string,
    filterOptions?: {
      itemsPerPage?: number
      pageNumber?: number
    },
  ) {
    const response =
      await ApolloService.client.query<V1RidaApiGetUserConfigHistoryResponse>({
        query: GetUserConfigHistoryGQL,
        variables: {
          userObjectId,
          filter: {
            itemsPerPage: filterOptions?.itemsPerPage,
            pageNumber: filterOptions?.pageNumber,
          },
        },
      })
    return response.data
  }
}

export { User as UserService }
