import gql from 'graphql-tag'

export const CreateOpsNoticeBoardGQL = gql`
  mutation (
    $title: String
    $message: String
    $valid: OpsNoticeBoardValidInput
    $priority: Int
  ) {
    createOpsNoticeBoard(
      title: $title
      message: $message
      valid: $valid
      priority: $priority
    )
  }
`
